<template>
  <div
    class="page-container"
  >
    <!-- User Info: Input Fields -->
    <validation-observer ref="rule">
      <b-form class="content-form">
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <!-- name -->
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Tiêu đề<span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
                :custom-messages="customMessages"
              >
                <b-form-input
                  id="basicInput"
                  v-model="newInfo.name"
                  :state="errors.length > 0 ? false:null"
                  :disabled="disableProp"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- Media -->
            <label
              for="InputHelp"
              style="font-size: 12px;"
            >Ảnh tin tức</label>
            <b-media class="mb-2 style-media">
              <template #aside>
                <div id="appImgNews">
                  <div
                    v-if="newInfo.avatar&&newInfo.avatar!==null&&newInfo.avatar!==''"
                    class="image-input image-input-active d-flex"
                  >
                    <div
                      class="image-preview"
                    >
                      <img
                        class="img-responsive h-100"
                        :src="($serverfile+newInfo.avatar)"
                      >
                      <b-dropdown
                        class="remove-file"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        :disabled="disableProp"
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="CameraIcon"
                            size="16"
                            class="icon-camera-hover"
                          />
                        </template>
                        <b-dropdown-item
                          :disabled="disableProp"
                          @click="openinput"
                        >
                          <feather-icon
                            icon="UploadIcon"
                            class="mr-50"
                          />
                          <span>Tải ảnh lên</span>
                        </b-dropdown-item>
                        <input
                          id="vue-file-upload-input"
                          type="file"
                          class="d-none"
                          accept=".gif,.jpg,.jpeg,.png"
                          :disabled="disableProp"
                          @change="addImage"
                        >
                        <b-dropdown-item
                          :disabled="disableProp"
                          @click="removeFile(index)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-50"
                          />
                          <span>Xóa ảnh</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div
                    v-else
                    class="image-input image-input-tbd d-flex"
                  >
                    <div
                      class="image-preview dropzone d-flex justify-content-center align-items-center"
                      :disabled="disableProp"
                      @click="openinput"
                    >
                      <feather-icon
                        icon="CameraIcon"
                        class="icon-camera"
                      />
                    </div>
                    <input
                      id="vue-file-upload-input"
                      type="file"
                      class="d-none"
                      :disabled="disableProp"
                      accept=".gif,.jpg,.jpeg,.png"
                      @change="addImage"
                    >
                  </div>
                </div>
              </template>
            </b-media>
          </b-col>

          <b-col
            md="6"
            xl="6"
            class="mb-1"
          >
            <b-form-group label-for="basicInput">
              <label for="InputHelp">Kiểu tin</label>
              <v-select
                v-model="newInfo.groupNew"
                :reduce="value => value.id"
                label="name"
                placeholder="Tin quan trọng"
                :options="typeNew|| []"
              >
                <template
                  slot="option"
                  slot-scope="option"
                >
                  {{ option.name }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ option.name }}
                  </div>
                </template>
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
            <!-- mô tả  -->
            <b-form-group label-for="basicInput">
              <label for="textarea-default">Mô tả</label>
              <b-form-textarea
                id="textarea-default"
                v-model="newInfo.description"
                rows="5"
                no-resize
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-form-group class="formEdit">
            <label for="InputHelp">Nội dung<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="content"
              rules="required"
              :custom-messages="contentErrorMessage"
            >
              <quill-editor
                v-model="newInfo.content"
                class="qlz-editor"
                :state="errors.length > 0 ? false:null"
                :disabled="disableProp"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="6"
            class="mb-1 checkbox-style"
          >
            <b-form-checkbox
              v-model="newInfo.isUse"
            >
              Được sử dụng
            </b-form-checkbox>
          </b-col>
        </b-row>
        <div class="demo-inline-spacing">
          <b-button
            variant="primary"
            @click="createHandle"
          >
            Lưu lại
          </b-button>
          <b-button
            variant="outline-primary"
            @click="$router.push({name: 'news-management'})"
          >
            Quay lại
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  BMedia,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import ConstantsApi from '../../constants/ConstantsApi'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    BMedia,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    vSelect,
    BFormTextarea,
    quillEditor,
    BFormCheckbox,
    BButton,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
    disableProp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      newInfo: {
        groupNew: '',
        content: '',
        locationPost: '',
        avatar: '',
        isUse: true,
        description: '',
        name: '',
        createBy: '',
        managementId: '',
      },
      customMessages: {
        required: 'Tiêu đề là bắt buộc',
      },
      contentErrorMessage: {
        required: 'Nội dung là bắt buộc',
      },
      showRepeatPass: false,
      emptyRepassword: false,
      editorOption: {
        modules: {
          imageResize: true,
          imageDrop: true,
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image', 'video', 'formula'],
          ],
        },
      },
      typeNew: [],
      selectedId: null,
    }
  },
  created() {
    this.fetchDataTypeNew()
    this.selectedId = this.$route?.params?.id
    if (this.selectedId) {
      this.newInfo.id = this.selectedId
      this.getNewsDetail(this.selectedId)
    }
  },
  methods: {
    // account
    openinput() {
      document.getElementById('vue-file-upload-input').click()
    },
    uploadPhoto() {
      this.openinput()
    },
    async addImage(e) {
      const formFile = e.target.files[0]
      const formData = new FormData()
      formData.append('formFile', formFile)
      const response = await axiosApiInstance.post(ConstantsApi.IMG_NEW, formData)
      this.newInfo.avatar = `${response.data}`
    },
    removeFile() {
      this.newInfo.avatar = null
    },

    // Fetch data KIỂU TIN TỨC
    async fetchDataTypeNew() {
      const { data } = await axiosApiInstance.get(ConstantsApi.TYPE_NEW)
      this.typeNew = data.data
    },

    // Lấy thông tin chi tiết tin tức
    async getNewsDetail(id) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.NEW_MANAGER_GET}/${id}`)
      this.newInfo = data
    },

    // tạo quản lý tin tức
    async createHandle() {
      if (this.selectedId) {
        await this.editHandle()
        return
      }
      const success = await this.$refs.rule.validate()
      if (success) {
        this.$showAllPageLoading()
        const { data } = await axiosApiInstance.post(ConstantsApi.NEW_MANAGER_CREATE, this.newInfo)
        if (data.success) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$router.push({ name: 'news-management' })
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm không thành công, vui lòng thử lại', {
            title: 'Thông báo',
            variant: 'warning',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      }
    },

    async editHandle() {
      const success = await this.$refs.rule.validate()
      if (success) {
        this.$showAllPageLoading()
        const { data } = await axiosApiInstance.put(ConstantsApi.NEW_MANAGER_UPDATE, this.newInfo)
        if (data.success) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$router.push({ name: 'news-management' })
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm không thành công, vui lòng thử lại', {
            title: 'Thông báo',
            variant: 'warning',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#appImgNews {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  margin-right: 20px;
  .btn {
    padding: 4px !important;
  }
  .image-input {
    padding: 3px;
  }

  .image-preview {
    width: 198px;
    height: 148px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 6px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .icon-camera {
    color: white;
    height: 50px;
    width: 50px;
  }
  .dropzone {
    width: 198px;
    height: 148px;
  }
  .dropzone {
    background: #efefef;
    border-radius: 7%;
  }
  .remove-file {
    position: absolute;
    border-radius: 20px;
    margin-left: -115px;
    margin-top: 50px;
    width: 40px;
    height: 40px;
  }
  .remove-file:hover {
    color: white;
    background-color: rgba(130, 134, 139, 0.65);
  }
  .icon-camera-hover {
    width: 28px;
    height: 28px;
    color: rgba(104, 104, 104, 0);
  }
  .icon-camera-hover:hover {
    color: white;
  }
}
.style-media {
  justify-content: center;
  display: grid;
}
.formEdit {
    padding-left: 1rem;
    padding-right: 1rem;
 .qlz-editor{
    display: flex;
    flex-direction: column;
    height: 228px;
  }
}
  .checkbox-style {
    display: grid;
    align-items: center;
  }
</style>
